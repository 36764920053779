export const CONNECT_CUSTOMER_INTERFACE_CSS_CLASS =
	'connect-customer-interface';

//session storage constants
export const SESSION_METADATA = 'metaData';
export const SESSION_CUSTOMER_INFO = 'customerInfo';
export const SESSION_CHAT_STATUS = 'chatStatus';
export const SESSION_CHAT_INFO = 'chatInfo';
export const SESSION_CHAT_DETAILS = 'chatDetails';
export const SESSION_CHAT_CHANNEL_TYPE = 'chat_channelType';
export const SESSION_CHAT_ENDED = 'chatEnded'; //this should be set to true or removed only when chat was ended
export const SESSION_CHATWIDGET_LOADING = 'chatWidgetLoading'; //this should be used only while chat widget is loading
export const SESSION_CHAT_RESIZED_STATUS = 'chatResizedStatus';

// http constants
export const HTTP_HEADER_CORRELATIONID = 'correlationId';
export const HTTP_HEADER_AUTHORIZATION = 'Authorization';
export const HTTP_HEADER_CONTACT_ID = 'contact-id';
export const HTTP_HEADER_PARTNER_ID = 'partner-id';
export const HTTP_HEADER_IDENTIFIER = 'identifier';
export const HTTP_HEADER_IDENTIFIERTYPE = 'identifier-type';

export const HTTP_RESOURCE_CHAT_SESSION = 'chatsession';
export const HTTP_RESOURCE_CHAT_SESSION_RETRIEVE = 'chatsession';
export const HTTP_RESOURCE_INVITE_TO_CHAT = 'inviteToChat';
export const HTTP_RESOURCE_AVAILABLE = 'available';
export const HTTP_RESOURCE_UPDATECONTACT = 'updatecontact';

export const unAuthenticatedChannelsProxy =
	'/interaction-management/customer/communications/connect/v1';
// Cookie related
export const CHAT_CONTACT_ID = 'chatContactId';
export const COOKIE_PATH = '/';
export const COOKIE_EXPIRY_TIME = new Date(
	new Date().getTime() + 60 * 60 * 1000,
);

// localStorage related
export const CHAT_CUSTOMER_IDENTIFIER_TYPE = 'identifier-type';
export const CHAT_CUSTOMER_IDENTIFIER_VALUE = 'identifier';

export const COBROWSE_VISITORID = 'cobrowse-visitorid';
export const COBROWSE_ENROLLMENT_VISITORID = 'enrollment';
export const COBROWSE_CONTACT_ID = 'contactId';
const isOnEnv = (env) => {
	const domain = window.location.origin;
	return domain.split('-')[0].indexOf(env) !== -1;
};
export const COOKIE_DOMAIN = isOnEnv('localhost')
	? 'localhost'
	: window.location.hostname.split('.')[
			window.location.hostname.split('.').length - 2
	  ] +
	  '.' +
	  window.location.hostname.split('.')[
			window.location.hostname.split('.').length - 1
	  ];
